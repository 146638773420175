import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ImagesData from "../../components/images"

const SolarSamaiPage = () => (
  <Layout>
    <SEO title="Solar Samai - (Brass metal)" />
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-12 flex-center justify-center position-ref font-weight-bold">
          <h3 className="agney-rainbow"><b>Solar Samai - (Brass metal)</b></h3>
        </div>
      </div> <br/>
      <div className="row">
        <div className="col-xs-2 col-md-2">
          <ImagesData imgName="samai_2" className="img-thumbnail img-responsive w-100 center-block" width="100%" alt="Agneya Products" title="Agneya Special Solar Products"/>
        </div>
        <div className="col-xs-10 col-md-10">
          <ol>
            <li><b className="font-weight-bold">Specifications</b> :
              <ul>
                <li>Samai material - Brass Metal</li>
                <li>Height - 21cm (approx)</li>
                <li>Base Acrylic Material (10cm*10cm*3.5cm)</li>
                <li>Count of Led lights - 5</li>
                <li>Solar charging : Min 6-8 hrs (good sunlight)</li>
              </ul>
            </li>
            <li>
<b className="font-weight-bold">Utilization</b> : 10 - 12 Hrs. (fully charged)
            </li>
            <li>
<b className="font-weight-bold">Additional feature</b> : It has USB facility 
(can be charged with mobile charger incase of low sunlight)
            </li>
            <li>
<b className="font-weight-bold">MRP</b> : ₹ 2000/-
            </li>
          </ol>
        </div>
      </div> <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>User Guide</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
The box will act as a solar charger of your Samai. 
<b className="font-weight-bold"> Before first use, keep the box for charging in the sunlight for atleast one day. </b>

For charging keep the box bit tilted (facing solar panel towards sunlight) at place 
where it can get maximum sunlight during the day. 

Once its charged, place the Samai on the box such that pin on the box goes inside the hole provided at the bottom of the Samai. 
Press the swtich provided in front of the box to switch on or off the Samai.
Once switched on, your Samai is lighten up and ready to use. 
        </div>
      </div>
      <br/>
      <div className="row font-weight-bold">
        <div className="col-xs-12 col-md-12">
          <h4><b>Precautions</b></h4>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12">
Every morning, detach the box from Samai and keep it for charging. A simple pull will separate the Samai from the box.

If Samai gets deem, detach it from the base. Please don’t use the Samai till box gets charged sufficiently.

If you find that there is low sunlight, don't worry. Keep it for charging. It will still get charged but it will require more time than usual.

If Samai is not in use, then it should  be kept separate from the box. Don’t keep the box in cupboard. Keep the box in the light (facing solar panel towards sunlight). It will prevent self discharging.

If there is no sunlight, mobile charger can be used for charging. It is SOLAR SAMAI, so please use mobile charger only in the absence of sunlight. Overcharging through mobile charger, may damage the battery. If any doubt, feel free to contact.
        </div>
      </div> <br/>
    </div>
  </Layout>
)

export default SolarSamaiPage
